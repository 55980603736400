<template>
  <loader :loading="tinderLoading" height="70vh" overlay>
    <div id="tinder" :class="isIOS && 'ios'" >
      <v-app-bar app flat color="transparent" >
        <v-btn icon @click="$router.back()" class="grey-light">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
      </v-app-bar>
      <Tinder
        ref="tinder"
        key-name="id"
        :queue.sync="queue"
        :offset-y="10"
        sync
        @submit="onSubmit"
        :key="tinderLoading"
        v-if="queue.length"
      >
        <template slot-scope="scope">
          <v-img
            cover
            center
            height="100%"
            v-if="scope.data"
            :src="scope.data.media[0]"
            :gradient="
              `180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 61.66%, #000000 ${
                scope.data.id === queue[0].id ? '100%' : '120%'
              }`
            "
            @click="$router.push(`/pet-detail/${scope.data.id}`)"
          >
            <div class="detail">
              <p class="name">
                {{ scope.data.name | trim(15) }}
                <v-icon color="white" class="ml-3"
                  >mdi-information-outline</v-icon
                >
              </p>
              <p class="kind">
                {{ scope.data.race ? scope.data.race.name : "--" }}, {{ scope.data.age }}
              </p>
            </div>
          </v-img>
        </template>
        <div class="like-pointer" slot="like">
          <div class="icon">
            <img src="@/components/svg/icon/listing-like.svg" alt="" />
            <p class="white--text">Like</p>
          </div>
        </div>
        <div class="nope-pointer" slot="nope">
          <div class="icon">
            <p class="white--text">Nope</p>
            <img src="@/components/svg/icon/listing-dislike.svg" alt="" />
          </div>
        </div>
        <div class="super-pointer" slot="super">
          <div class="icon">
            <img src="@/assets/svg/app-paw.svg" alt="" />
            <p class="white--text">Request Adoption</p>
          </div>
        </div>
      </Tinder>

      <div class="tinder-empty-state" v-if="!queue.length && !tinderLoading">
        <v-card elevation="0" width="100%" class="text-center">
          <v-card-text>No Result Found :(</v-card-text>
        </v-card>
      </div>

      <div class="text-center pt-5 pb-8">
        <v-btn
          fab
          dark
          small
          @click="decide('nope')"
          elevation="0"
          class="decide-btn"
        >
          <v-img src="@/components/svg/icon/close-danger.svg" contain></v-img>
        </v-btn>

        <v-btn
          fab
          dark
          color="primary"
          class="mx-6 decide-btn"
          @click="decide('super')"
          elevation="0"
        >
          <v-img src="@/assets/svg/app-paw.svg" contain></v-img>
        </v-btn>

        <v-btn
          fab
          dark
          small
          color="secondary"
          @click="decide('like')"
          elevation="0"
          class="decide-btn"
        >
          <v-icon dark>mdi-heart</v-icon>
        </v-btn>
      </div>

      <BottomBar />
    </div>
  </loader>
</template>

<script>
import Tinder from "vue-tinder";
import BottomBar from "@/components/bottom-bar/bottom-bar";

import { createNamespacedHelpers } from "vuex";
const petModule = createNamespacedHelpers("pet");


import MixDevice from '@/mixin/mix-device'

export default {
  name: "listing",
  components: { Tinder, BottomBar },
  mixins:[MixDevice],
  data: () => ({
    offset: 0,
    queue: [],
    history: [],
  }),
  computed: {
    ...petModule.mapState(["tinderLoading", "tinderListing", "tinderNextPage"]),
  },
  async created() {
    await this.FETCH_TINDER_LISTING();
    this.mock();
  },
  methods: {
    ...petModule.mapMutations(["CLEAR_TINDER_LISTING"]),
    ...petModule.mapActions([
      "FETCH_TINDER_LISTING",
      "PET_FAVORITE_HANDLER",
      "PET_DISLIKE_HANDLER",
    ]),
    async mock(count = 3) {
      const list = [];
      for (let i = 0; i < count; i++) {
        if (this.tinderListing[this.offset]) {
          list.push(this.tinderListing[this.offset]);
          this.offset++;
        }
      }

      if (this.offset == this.tinderListing.length - 2 && this.tinderListing) {
        await this.FETCH_TINDER_LISTING();
      }
      this.queue = this.queue.concat(list);
    },
    async onSubmit(obj) {
      const { type, item } = obj;

      if (type === "like") {
        await this.PET_FAVORITE_HANDLER(item.id);
      } else if (type === "nope") {
        await this.PET_DISLIKE_HANDLER(item.id);
      } else {
        this.$router.push(`/pet-detail/${item.id}`);
      }

      if (this.queue.length < 3) {
        this.mock();
      }
      this.history.push(obj.item);
    },
    async decide(choice) {
      if (this.queue.length) this.$refs.tinder.decide(choice);
    },
  },
  mounted() {
    document.querySelector(".v-main").classList.add("bg-yellow");
    document.querySelector("body").classList.add("overflow-hidden");
  },
  beforeDestroy() {
    document.querySelector(".v-main").classList.remove("bg-yellow");
    document.querySelector("body").classList.remove("overflow-hidden");
    this.CLEAR_TINDER_LISTING();
  },
};
</script>

<style lang="scss">
#tinder {
  height: calc(100vh - 56px - 56px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;

  .tinder-card:not(:first-child) {
    // opacity: 0.8 !important;
    p {
      display: none;
    }
  }

  .tinder-empty-state,
  .vue-tinder {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0px;
    margin: auto;
    width: calc(100% - 20px);
    height: calc(100% - 23px - 65px - 47px - 56px);
    min-width: 270px;
    max-width: 330px;

   

    // @media (min-height: 350px) {
    //   height: calc(100% - 23px - 65px - 47px - 56px - 50px);
    // }
  }

   &.ios{

  .tinder-empty-state,
  .vue-tinder {


    height: calc(100% - 23px - 65px - 47px - 56px - 56px);
      top:56px;
  }

    }

  .tinder-empty-state {
    display: grid;
    place-items: center;
  }

  .super-pointer,
  .nope-pointer,
  .like-pointer {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    .icon {
      position: absolute;
      display: flex;
      align-items: center;

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0;
      }
    }
  }

  .like-pointer {
    background-image: linear-gradient(
        180deg,
        #5defcc 0%,
        rgba(93, 239, 204, 0) 100%
      ),
      linear-gradient(180.03deg, rgba(0, 0, 0, 0) 56.93%, #000000 99.97%);

    .icon {
      top: 1rem;
      left: 1rem;
      p {
        margin-left: 10px;
      }

      img {
        transform: rotate(-15deg);
      }
    }
  }

  .nope-pointer {
    background: linear-gradient(180deg, #ff5f5f 0%, rgba(255, 95, 95, 0) 100%),
      linear-gradient(180.03deg, rgba(0, 0, 0, 0) 56.93%, #000000 99.97%);

    .icon {
      top: 1rem;
      right: 1rem;

      p {
        margin-right: 10px;
      }

      img {
        transform: rotate(15deg);
      }
    }
  }

  .super-pointer {
    background: linear-gradient(360deg, #0dbff2 0%, rgba(255, 95, 95, 0) 100%),
      linear-gradient(360deg, rgba(0, 0, 0, 0) 56.93%, #000000 99.97%);

    .icon {
      display: block;
      bottom: 3rem;
      width: 100%;
      text-align: center;
    }
  }

  .rewind-pointer {
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 10px;
    width: 112px;
    height: 78px;
  }

  .detail {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    p {
      margin-bottom: 0;
    }
    p.name {
      font-weight: 500;
      font-size: 24px;
      line-height: 22px;
      color: #ffffff;
      margin-bottom: 10px;
    }

    p.kind {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
    }
  }
}

.decide-btn {
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.1));
}
</style>
